export enum SectionWidth {
  FULL = '100%',
  WIDE = '1280px',
  MIDDLE_WIDE = '1170px',
  COMMON = '1080px',
  NARROW = '890PX',
}

export enum SectionType {
  ABOUT_HERO_IMAGE = 'about-hero-image',
  ABOUT_LEFT_IMAGE = 'about-left-image',
  ABOUT_LEFT_VIDEO = 'about-left-video',
  ABOUT_ORANGE_ALERT = 'about-orange-alert',
  ABOUT_NC_OFFER_PACKAGES = 'about-nc-offer-packages',
  ABOUT_PSYCHICS_SET_MEDIUM_SIZE_IMAGES = 'about-psychics-set-medium-size-images',

  HOROSCOPES_SIGNS_SIGN_INTRO = 'horoscopes-signs-sign-intro',
  HOROSCOPES_SIGNS_HOROSCOPES_AND_PREDICTIONS = 'horoscopes-signs-horoscopes-and-predictions',
  HOROSCOPES_SIGNS_ALL_ABOUT_SIGNS = 'horoscopes-signs-all-about-signs',
  HOROSCOPES_SIGNS_MYSTERY_DIRECTIONS = 'horoscopes-signs-mystery-directions',

  CONTACT_US_GET_IN_TOUCH = 'contact-us-get-in-touch',
  CONTACT_US_HELP_CENTER = 'contact-us-help-center',

  CIRCLE_IMAGE_DESCRIPTION_BUTTON = 'circle-image-description-button',
  TEXT_AND_SIDE_ASSET = 'text-and-side-asset',
  MYSTERY_LINKS_SET = 'mystery-links-set',
  PSYCHICS_SET_MEDIUM_SIZE_IMAGES_CLONE = 'psychics-set-medium-size-images-clone',
  ORANGE_ALERT_CLONE = 'orange-alert-clone',
  ORANGE_ALERT_BIG = 'orange-alert-big',
  NC_OFFER_PACKAGES_CLONE = 'nc-offer-packages-clone',
  EXPANDABLE_ITEMS_LIST = 'expandable-items-list',
  CIRCLE_IMAGE_DESCRIPTION_BUTTON_CLONE = 'circle-image-description-button-clone',
  CONTENT_DIVIDER = 'content-divider',
  CTA_FLAT_BANNER = 'cta-flat-banner',
  FIXED_BANNER = 'fixed-banner',
  INLINE_HTML = 'inline-html',
  EXPONEA_PSYCHICS_LIST = 'exponea-psychics-list',
  EXPONEA_PSYCHIC_PROFILE = 'exponea-psychic-profile',
  PSYCHICS_TIERS_HARDCODED = 'psychics-tiers-hardcoded',
  GRID_HERO_BANNER = 'grid-hero-banner',
  PLAIN_ITEMS_WITH_DESCRIPTION = 'plain-items-with-description',
  TEMPLATE_HTML_PSYCHICS = 'template-html-psychics',
  CUSTOMERS_TESTIMONIALS = 'customers-testimonials',
  OPTIONAL_TRUSTPILOT = 'optional-trustpilot',
  FLEX_COLUMNS = 'flex-columns',
  EC_OFFERS_LIST = 'ec-offers-list',
  EC_HORO_SIGH_SHORT_INFO = 'ec-horo-sign-short-info',
  EC_PRICE_CARDS_WITH_KARMA = 'ec-price-cards-with-karma',
  EC_GREET_AND_PSYCHIC_NOTIFICATIONS = 'ec-greet-and-psychic-notifications',
  SECTIONS_COMBINER = 'sections-combiner',
  EC_GREET_AND_OFFERS = 'ec-greeting-and-offers',
  PSYCHICS_SET_SWITCH_API = 'psychics-set-switch-api',
  CUSTOMERS_TESTIMONIALS_LIST = 'customers-testimonials-list',
  SIMILAR_PSYCHICS = 'similar-psychics',
  APPOINTMENT_CALENDAR = 'appointment-calendar',
  PSYCHIC_BIO_INFORMATION = 'psychic-bio-information',
  CUSTOMER_PENDING_FEEDBACK = 'customer-pending-feedback',
}

export enum SectionExtraData {
  TITLE_ALIGN = 'titleAlign',
  P_ALIGN = 'pAlign',
  ASSET_ALIGN = 'assetAlign',
  ITEMS_AMOUNT = 'itemsAmount',
  BUTTON_WITH_CONTENT = 'buttonWithContent',
  VERTICAL_PADDING = 'verticalPadding',
  VIDEO_AUTOPLAY = 'videoAutoplay',
  SECTION_SIZE = 'sectionSize',
  LOADING_MODE = 'loadingMode',
  CONTENT_DIRECTION = 'contentDirection',
  DIVIDER_WIDTH = 'dividerWidth',
  DIVIDER_HEIGHT = 'dividerHeight',
  H1_SIZE = 'h1Size',
  COLOR_THEME = 'colorTheme',
  MOBILE_ITEMS_LAYOUT = 'mobileItemsLayout',
  IS_MOBILE_VISIBLE = 'isMobileVisible',
  PSYCHIC_CATEGORY = 'psychicCategory',
  HORIZONTAL_PADDING = 'horizontalPadding',
  EXPONEA_TYPE = 'exponeaType',
  EXPONEA_OPTIONS = 'exponeaOptions',
  EXPONEA_SIZE = 'exponeaSize',
  EXPONEA_RECOMMENDATION_ID = 'exponeaRecommendationId',
  ITEMS_LAYOUT = 'itemsLayout',
  PSYCHIC_CARD_APPEARANCE = 'psychicCardAppearance',
  GRID_HERO_BANNER = 'gridHeroBanner',
  BLOCK_ALIGN = 'blockAlign',
  MOBILE_BLOCK_JUSTIFY = 'mobileBlockJustify',
  IS_SIMPLE_VIEW = 'isSimpleView',
  CONSIDER_ONLY_PRESENTED = 'considerOnlyPresented',
  MAX_WIDTH = 'maxWidth',
  PSYCHIC_PHOTO_MODULE = 'psychicPhotoModule',
  API_TO_USE = 'apiToUse',
}

export enum Status {
  BUSY = 'busy',
  AVAILABLE = 'available',
  OFFLINE = 'offline',
  PENDING = 'pending',
  ON_BREAK = 'onbreak',
  DISPLAY_ON_BREAK = 'on a break',
  ON_CALL = 'oncall',
}

export enum FirestoreChangeType {
  ADDED = 'added',
  DELETED = 'deleted',
  MODIFIED = 'modified',
}

export enum AlertTypes {
  LOGIN_FAILED = 'login-failed',
  BAD_CREDENTIALS = 'bad-credentials',
  UNKNOWN_USERNAME = 'unknown-username',
  SERVER_ERROR = 'server-error',
  WRONG_EMAIL = 'wrong-email',
  EMAIL_REQUIRED = 'email-required',
  WRONG_FIRST_NAME = 'wrong-first-name',
  FIRST_NAME_REQUIRED = 'first-name-required',
  UNDER_AGE = 'under-age',
  BIRTHDAY_REQUIRED = 'birthday-required',
}

export enum AuthProviders {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}

/**
 * First customers that have account but were logged out named as EC, more info here:
 * {@link https://outlook.atlassian.net/wiki/spaces/CC/pages/2029387906/CMS+customer+types|(CMS customer types)}.
 * However now we don't need to this and such customers are counted
 * as NC. So current AC can be considered as CP EC. And current EC just used as
 * a value in some places. In future AC can be fully changed to EC
 *  */
export enum CustomerType {
  NC = 'nc',
  NC_LEAD = 'nc lead',
  EC = 'ec',
}

export enum HoroscopeType {
  DAILY_YESTERDAY = 1,
  DAILY_TODAY = 2,
  DAILY_TOMORROW = 3,
  WEEKEND = 5,
  WEEKLY = 8,
  MONTHLY = 10,
  MONTHLY_MONEY = 11,
  YEARLY = 13,
}

export enum ViewerDevice {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

export enum PsychicPackageName {
  POPULAR = 'popular',
  PREFERRED = 'preferred',
  PREMIER = 'premier',
}

export enum GAReplacementValue {
  FULL_URL = 'fullUrl',
  SHORT_URL = 'shortUrl',
  EXT_ID = 'extId',
  PSYCHIC_NAME = 'psychicName',
  CUSTOMER_ID = 'customerId',
  CUSTOMER_NAME = 'customerName',
  NAME = 'name',
  ACCORDION = 'accordion',
  POSITION = 'position',
  ZODIAC_SIGN = 'zodiacSign',
  TELEPHONE = 'telephone',
  OFFER_ID = 'offerId',
  PRICE = 'price',
  USER = 'user',
  MAJOR_CATEGORY = 'majorCategory',
  CATEGORY = 'category',
}

export enum MetaReplacementValue {
  SITE_NAME = 'siteName',
  KEYWORD = 'keyword',
  TITLE = 'title',
  ZODIAC_SIGN = 'zodiacSign',
  HOROSCOPE_TYPE = 'horoscopeType',
  CURRENT_DATE = 'currentDate',
}

export enum NodeEnv {
  PROD = 'production',
  DEV = 'development',
  TEST = 'test',
}

export enum InputPurpose {
  NAME = 'name',
  SURNAME = 'surname',
  DATE = 'date',
  EMAIL = 'email',
  PASSWORD = 'password',
  DESCRIPTION = 'description',
  TELEPHONE = 'telephone',
}

export enum FreeHoroscopeModalInputs {
  EMAIL = 'email',
  NAME = 'name',
  MONTH = 'month',
  YEAR = 'year',
  DAY = 'day',
  IS_EMAIL_VALID = 'isEmailValid',
  IS_NAME_VALID = 'isNameValid',
  IS_BIRTH_DATE_VALID = 'isBirthDateValid',
}

export enum ModalWindowStepNumber {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
}

export enum NavigationLocation {
  FOOTER = 'Footer',
  HEADER = 'Header',
}

export enum SocialMediaReplacementValue {
  ANCHOR = 'anchor'
}

export enum RelationalOperator {
  EQUAL = 'equal',
  EQUAL_WITHOUT_TYPE = 'equalWithoutType',
  NOT_EQUAL = 'notEqual',
  NOT_EQUAL_WITHOUT_TYPE = 'notEqualWithoutType',
  GREATER_THEN = 'greaterThen',
  LESS_THEN = 'lessThan',
  GREATER_OR_EQUAL = 'greaterOrEqual',
  LESS_OR_EQUAL = 'lessOrEqual',
}

export enum ImageFitMode {
  PAD = 'pad',
  FILL = 'fill',
  SCALE = 'scale',
  CROP = 'crop',
  THUMB = 'thumb',
}

export const ErrorCodeSignUpNewsletters = {
  EMAIL: 1,
  DOB: 4,
};

export enum CommonSize {
  XX_SMALL = 'xXSmall',
  X_SMALL = 'xSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  ENORMOUS = 'enormous',
}

export enum ElementAlign {
  LEFT = 'left',
  LEFT_STRICT = 'leftStrict',
  CENTER = 'center',
  RIGHT = 'right',
  RIGHT_STRICT = 'rightStrict',
}

export enum JustifyContent {
  CENTER = 'center',
  SPACE_BETWEEN = 'spaceBetween',
  SPACE_AROUND = 'spaceAround',
}

export enum Heading {
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
}

export enum LoadingMode {
  EAGER = 'eager',
  LAZY = 'lazy',
}

export enum CommonContentfulSlug {
  CONTENT = 'content',
  TOP_DIVIDER = 'topDivider',
  BOTTOM_DIVIDER = 'bottomDivider',
  NEXT_ARROW = 'nextArrow',
  PREV_ARROW = 'prevArrow',
}

export enum Position {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
}

export enum FlexDirection {
  ROW = 'row',
  COLUMN = 'column',
  COLUMN_REVERSE = 'columnReverse',
  ROW_REVERSE = 'rowReverse',
}

export enum DividerWidth {
  HOROSCOPE = 'horoscope',
}

export enum ColorTheme {
  BLACK = 'black',
  WHITE = 'white',
}

export const enum MobileItemsLayout {
  GRID_2 = 'grid2',
  GRID_3 = 'grid3',
  FLEX_ROW = 'flexRow',
  FLEX_COLUMN = 'flexColumn',
}

export const enum ItemsLayout {
  GRID_2 = 'grid2',
  GRID_3 = 'grid3',
  GRID = 'grid',
  ROW = 'row',
  COLUMN = 'column',
}

export enum PsychicCategoryType {
  ALL = 'all',
  RISING_STARS = 'risingStars',
  STAFF_PICKS = 'staffPicks',
  PREMIER = 'premier',
  NEW = 'new',
  LOVE = 'love',
  CAREER = 'career',
  DESTINY_OR_LIFE_PATH = 'destinyOrLifePath',
  MONEY = 'money',
  PET = 'pet',
  PAST_LIVES = 'pastLives',
  DECEASED_LOVED = 'deceasedLoved',
  LOST_OBJECTS = 'lostObjects',
  MISSING_PERSONS = 'missingPersons',
  EMPATH = 'empath',
  MEDIUM = 'medium',
  CLAIRVOYANT = 'clairvoyant',
  CLAIRAUDIENT = 'clairaudient',
  CLAIRSENTIENT = 'clairsentient',
  DREAM_ANALYSIS = 'dreamAnalysis',
  REMOTE_VIEWING = 'remoteViewing',
  CHANNELING = 'channeling',
  AUTOMATIC_WRITING = 'automaticWriting',
  NO_TOOLS = 'noTools',
  TAROT = 'tarot',
  ASTROLOGY = 'astrology',
  NUMEROLOGY = 'numerology',
  CRYSTALS = 'crystals',
  ORACLE_CARDS = 'oracleCards',
  RUNES = 'runes',
  I_CHING = 'i-Ching',
  PENDULUM = 'pendulum',
  INSPIRATIONAL = 'inspirational',
  COMPASSIONATE = 'compassionate',
  STRAIGHTFORWARD = 'straightforward',
}

export enum OAPlatform {
  IOS = 'mobile web ios',
  ANDROID = 'mobile web android',
  DESKTOP = 'desktop web',
}

export enum CloudFrontViewer {
  DESKTOP = 'cloudfront-is-desktop-viewer',
  MOBILE = 'cloudfront-is-mobile-viewer',
  TABLET = 'cloudfront-is-tablet-viewer',
  IOS = 'cloudfront-is-ios-viewer',
  ANDROID = 'cloudfront-is-android-viewer',
}

export const enum ExponeaType {
  SUGGESTED = 'suggested',
  RECOMMENDED = 'recommended',
}

export const enum ExponeaPsychicsResults {
  ALL = 'Available/Not Available',
  AVAILABLE = 'Available Only',
}

export const enum PsychicCardAppearance {
  SIMPLE_ADDITIONAL = 'Simple w/Additional Info',
  SIMPLE = 'Simple',
  SIMPLE_WITH_DESCRIPTION = 'Simple with Description',
  TILE = 'Tile',
  LIST = 'List',
  SIMPLE_VERTICAL = 'Simple Vertical',
  SIMPLE_HORIZONTAL = 'Simple Horizontal',
}

export const enum FooterType {
  COMMON = 'common',
  LIGHT = 'light',
  ORANGE = 'orange',
}

export const enum ExponeaSortOrder {
  ASC = 'ascending',
  DESC = 'descending'
}

export enum GridHeroBannerType {
  TAROT = 'tarot',
  LOVE1 = 'love1',
  AFFILIATES = 'affiliates',
}

export enum LoginResult {
  SUCCESS = 1,
  FAILED = 2,
  ACCESS_DENY = 3,
  ACCESS_DENY_EMAIL = 4,
  ACCESS_DENY_DOB = 5,
  LOGIN = 6,
  INCORRECT_PASSWORD = 7,
  EMAIL_NOT_EXIST_IN_DB = 8,
  LOGIN_MISMATCH = 9,
}

export const enum PsychicPhotoModule {
  WITH_GRADIENT = 'With Gradient',
  WITH_RATE = 'With Rate',
  WITH_SELECTION = 'With Selection',
  WITH_READING_DETAIL = 'With Reading Detail',
}

export const enum PsychisReadingType {
  CALLBACK = 'Callback',
  APPOINTMENT = 'Appointment',
  CHAT = 'Chat',
  MESSAGE = 'Message',
  PENDING_REQUEST = 'Pending Request',
  CANCELLED_CALLBACK = 'Cancelled Callback',
  CANCELLED_APPOINTMENT = 'Cancelled Appointment',
  CANCELLED_CHAT = 'Cancelled Chat',
  CANCELLED_MESSAGE = 'Cancelled Message',
  MISSED_CALLBACK = 'Missed Callback',
  MISSED_APPOINTMENT = 'Missed Appointment',
  MISSED_CHAT = 'Missed Chat',
  MISSED_MESSAGE = 'Missed Message',
  UPCOMING = 'Upcoming',
}

export enum PaginationType {
  NUMBERS = 'Numbers',
  LOAD_MORE = 'Load More',
  SCROLL = 'Scroll',
}

export enum PsychicLabel {
  RISING_STAR = 'rising-star',
  PREMIER = 'premier',
  NEW = 'new',
  STAFF_PICK = 'staff-pick',
  CUSTOMER_PICK = 'customer-favorite',
  ELITE = 'elite',
  SELECT = 'select',
}
