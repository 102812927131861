import { PageStore, ReducerAction } from 'app-redux/types/storeTypes';
import * as types from 'app-redux/types/actionTypes';

const initialState: PageStore = {
  pages: {},
  slug: '',
  referrer: '',
  data: {},
};

const reducer = (state = initialState, { payload, type }: ReducerAction): PageStore => {
  switch (type) {
    case types.SET_PAGES:
      return {
        ...state,
        pages: {
          ...state.pages,
          [payload.key]: payload.value,
        },
        slug: payload.key,
      };
    case types.SET_SLUG:
      return {
        ...state,
        slug: payload,
      };
    case types.SET_REFERRER:
      return {
        ...state,
        referrer: payload,
      };
    case types.SET_PAGE_DATA:
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    default: return state;
  }
};

export default reducer;
