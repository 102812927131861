import {
  SET_API_ERROR,
  SET_API_RESPONSE,
  SET_API_STATUS,
} from 'app-redux/types/actionTypes';
import type { APIStore, ReducerAction } from 'app-redux/types/storeTypes';

const initialState: APIStore = {
};

const reducer = (state = initialState, { payload, type }: ReducerAction) => {
  if (!payload?.key) {
    return state;
  }

  const restApiObject = state[payload.key] || {};
  const apiName = payload.key;

  switch (type) {
    case SET_API_STATUS:
      return {
        ...state,
        [apiName]: { ...restApiObject, status: payload.value },
      };
    case SET_API_RESPONSE:
      return {
        ...state,
        [apiName]: { ...restApiObject, response: payload.value, status: 'success' },
      };
    case SET_API_ERROR:
      return {
        ...state,
        [apiName]: { ...restApiObject, error: payload.value, status: 'error' },
      };
    default: return state;
  }
};

export default reducer;
