import * as types from 'app-redux/types/actionTypes';
import { AppServerStore, ReducerAction } from 'app-redux/types/storeTypes';
import scssVariables from 'src/styles/_commonVariables.module.scss';
import { OAPlatform, ViewerDevice } from 'constants/enums';

const initialState: AppServerStore = {
  isMobileHeaderNavActive: false,
  scssVariables,
  isMobileViewWidth: false,
  viewerDevice: ViewerDevice.DESKTOP,
  oaPlatform: OAPlatform.DESKTOP,
  serverCookies: {},
};

const reducer = (state = initialState, { payload, type }: ReducerAction) => {
  switch (type) {
    case types.SET_MOBILE_HEADER_NAV_ACTIVITY_STATE:
      return { ...state, isMobileHeaderNavActive: payload };
    case types.SET_MOBILE_VIEW_WIDTH_STATE:
      return { ...state, isMobileViewWidth: payload };
    case types.SET_SCSS_VARIABLES:
      return { ...state, scssVariables: payload };
    case types.SET_VIEWER_DEVICE:
      return { ...state, viewerDevice: payload };
    case types.SET_OA_PLATFORM:
      return { ...state, oaPlatform: payload };
    case types.SET_SERVER_COOKIES:
      return { ...state, serverCookies: payload };
    default: return state;
  }
};

export default reducer;
