import * as types from 'app-redux/types/actionTypes';
import type { AppStore, ReducerAction } from 'app-redux/types/storeTypes';

const initialState: AppStore = {
  userAgent: null,
  isDesktopRequested: false,
  isFirebaseLoaded: false,
  isFirebaseRequired: false,
  isLoading: false,
  clientWidth: 0,
  chatBootStatus: null,
  ctmNumber: '',
  isSidebarActive: false,
};

const reducer = (state = initialState, { payload, type }: ReducerAction) => {
  switch (type) {
    case types.SET_USER_AGENT:
      return { ...state, userAgent: payload };
    case types.SET_DESKTOP_REQUESTED_STATE:
      return { ...state, isDesktopRequested: payload };
    case types.SET_FIREBASE_LOADED_STATE:
      return { ...state, isFirebaseLoaded: payload };
    case types.SET_LOADING_STATE:
      return { ...state, isLoading: payload };
    case types.SET_FIREBASE_REQUIRED_STATE:
      return { ...state, isFirebaseRequired: payload };
    case types.SET_CLIENT_WIDTH:
      return { ...state, clientWidth: payload };
    case types.SET_CTM_NUMBER:
      return { ...state, ctmNumber: payload };
    case types.SET_BOOT_STATUS:
      return { ...state, chatBootStatus: payload };
    case types.SET_SIDEBAR_ACTIVITY_STATE:
      return { ...state, isSidebarActive: payload };
    default: return state;
  }
};

export default reducer;
