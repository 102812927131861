export class StoreKeeper {
  private static _store: Record<string, any>;

  public static getStore() {
    return StoreKeeper._store;
  }

  public static setStore(value: Record<string, any>) {
    StoreKeeper._store = value;
  }
}
