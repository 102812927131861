import { AuthStore, ReducerAction } from 'app-redux/types/storeTypes';
import * as types from 'app-redux/types/actionTypes';

const initialState: AuthStore = {
  isAuthenticated: false,
  user: null,
};

const reducer = (state = initialState, { payload, type }: ReducerAction) => {
  switch (type) {
    case types.SET_AUTHENTICATED_STATE:
      return { ...state, isAuthenticated: payload };
    case types.SET_USER:
      return { ...state, user: payload };
    default: return state;
  }
};

export default reducer;
