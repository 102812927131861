import { createStore } from 'redux';
import { createWrapper } from 'next-redux-wrapper';

import { Store as MainStore } from 'app-redux/types/storeTypes';
import mainReducer from 'app-redux/store/rootReducer';
import enhancer from 'app-redux/store/enhancer';

import { StoreKeeper } from './storeKeeper';

const makeStore = () => {
  const store = createStore(mainReducer, enhancer);
  StoreKeeper.setStore(store);
  store.getState();

  return store;
};

const wrapper = createWrapper<MainStore>(makeStore, { debug: process.env.NODE_ENV === 'development' });

export { wrapper };
