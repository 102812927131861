import { Context, enableContextDevTools } from '@uniformdev/context';
import type { Entry } from 'contentful';

import manifest from 'src/__generated__/contextManifest.json';

export const getUniformContext = (...parameters: Array<any>) => new Context({
  // @ts-ignore
  manifest,
  plugins: [
    enableContextDevTools(),
  ],
  ...parameters,
});

export const mapSectionToUniformSection = (entry: Entry<any> & Record<string, any>) => {
  const modifiedEntry = entry;

  if (!modifiedEntry.id && entry.sys?.id) {
    modifiedEntry.id = entry.sys?.id;
  }

  if (modifiedEntry.unfrmOptEnrichmentTag) {
    modifiedEntry.enrichments = entry.unfrmOptEnrichmentTag;
    delete modifiedEntry.unfrmOptEnrichmentTag;
  }

  if (modifiedEntry.unfrmOptPersonalizationCriteria) {
    modifiedEntry.pz = entry.unfrmOptPersonalizationCriteria;
    delete modifiedEntry.unfrmOptPersonalizationCriteria;
  }

  return modifiedEntry;
};
