import { applyMiddleware } from 'redux';
import logger from 'redux-logger';

const applyEnhancer = (nodeEnv: string) => {
  if (nodeEnv === 'development') {
    return applyMiddleware(logger);
  }
};

const enhancer = applyEnhancer(process.env.NODE_ENV);

export default enhancer;
